






















































import TextCopyButton from "@/components/shared/TextCopyButton.vue";
import { Pharmacy } from "@/models/Pharmacy";
import { PharmacyLicense } from "@/models/PharmacyLicense";
import { useHttpMedshare } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: { TextCopyButton },
  props: {
    pharmacy: {
      type: Object,
      required: true,
    },
    deal: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },

  },
  setup(props) {
    const isShow = ref<boolean>(false);
    const isLicenseLoaded = ref<boolean>(false);
    const pharmacyLicense = ref<PharmacyLicense>();
    const httpMedshare = useHttpMedshare();
    const url = computed(() => `/api/v2/pharmacy/pharmacies/${(props.pharmacy as Pharmacy).id}/license`);

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;

    const isMyPharmacy = computed(() => (props.pharmacy as Pharmacy).id === myPharmacyId.value);

    const isMessageLoaded = ref<boolean>(false);
    const subject = ref<string>();
    const body = ref<string>();

    async function fetchLicense() {
      try {
        const response = await httpMedshare.get(url.value);
        pharmacyLicense.value = new PharmacyLicense(response.data);
      } finally {
        isLicenseLoaded.value = true;
      }
    }

    async function fetchMessage() {
      if (!props.deal) return; 
      try {
        const preview_url = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/deals/${props.deal?.id}/preview`);
        const response_preview = await httpMedshare.get(preview_url.value);
        subject.value = response_preview.data.subject;
        body.value = response_preview.data.body;
      } finally {
        isMessageLoaded.value = true;
      }
    }

    function showModal() {
      isShow.value = true;
      fetchLicense();
      if (props.type === "buying" && props.deal) fetchMessage();
    }

    return {
      isShow,
      isLicenseLoaded,
      pharmacyLicense,
      isMyPharmacy,
      showModal,
      subject,
      body,
      isMessageLoaded,
    };
  },
});
