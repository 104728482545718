export class Pharmacy {
  id!: number;
  name!: string;
  micode!: string;
  address1!: string;
  address2!: string;
  address3!: string;
  address4?: string;
  phone!: string;
  fax!: string;
  email!: string;
  created_at!: string;
  updated_at!: string;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
  }

  get fullAddress(): string {
    return `${this.address1}${this.address2}${this.address3}${this.address4}`;
  }
}
