











































































































import DealBuyModal from "@/components/deals/DealBuyModal.vue";
import DealCompleteModal from "@/components/deals/DealCompleteModal.vue";
import DealHistories from "@/components/deals/DealHistories.vue";
import DealImageCard from "@/components/deals/DealImageCard.vue";
import DealIncompleteModal from "@/components/deals/DealIncompleteModal.vue";
import DealItemView from "@/components/deals/DealItemView.vue";
import DealPutDownModal from "@/components/deals/DealPutDownModal.vue";
import DealReOpenModal from "@/components/deals/DealReOpenModal.vue";
import DealStepProgress from "@/components/deals/DealStepProgress.vue";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { Deal } from "@/models/Deal";
import { useHttpMedshare } from "@/plugins/axios";
import { useRouter } from "@/router/useRouter";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: {
    DealIncompleteModal,
    DealCompleteModal,
    DealBuyModal,
    DealHistories,
    DealImageCard,
    DealItemView,
    DealPutDownModal,
    DealReOpenModal,
    DealStepProgress,
    LoadingAlert,
  },
  setup() {
    const router = useRouter();
    const httpMedshare = useHttpMedshare();

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const isOpenDealCompleteModal = ref<boolean>(false);
    const isOpenDealIncompleteModal = ref<boolean>(false);
    const isOpenDealPutDownModal = ref<boolean>(false);
    const isOpenDealReOpenModal = ref<boolean>(false);
    const isOpenBuyModal = ref<boolean>(false);

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;

    const dealId = router.currentRoute.params.id;
    const dealUrl = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/deals/${dealId}`);
    const deal = ref<Deal | null>(null);

    const buyerPharmacyName = computed(() => {
      if (!deal.value) return "-";
      if (!deal.value.buyer_pharmacy) return "-";
      return deal.value.buyer_pharmacy.name || "-";
    });

    const discountRateTooltip = {
      html: true,
      content: "期限1年以上: 40%<br />半年〜1年: 50%<br />半年以内: 60％<br /><br />ジェネリックはさらに20％加えた率を推奨しております。",
    };

    async function load() {
      if (isLoading.value) return;
      if (!myPharmacyId.value) return;

      try {
        isLoading.value = true;
        const response = await httpMedshare.get(dealUrl.value);
        deal.value = new Deal(response.data);
        isFirstLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    }

    async function openBuyModal() {
      isOpenBuyModal.value = true;
    }

    async function openDealCompleteModal() {
      isOpenDealCompleteModal.value = true;
    }
    async function openDealIncompleteModal() {
      isOpenDealIncompleteModal.value = true;
    }
    async function openDealPutDownModal() {
      isOpenDealPutDownModal.value = true;
    }
    async function openDealReOpenModal() {
      isOpenDealReOpenModal.value = true;
    }

    async function goEdit() {
      await router.push({ name: "取引編集", params: { id: dealId.toString() } });
    }

    watch(myPharmacyId, () => load());
    load();

    return {
      myPharmacyId,
      isFirstLoading,
      isLoading,
      isOpenBuyModal,
      isOpenDealCompleteModal,
      isOpenDealIncompleteModal,
      isOpenDealPutDownModal,
      isOpenDealReOpenModal,
      deal,
      buyerPharmacyName,
      discountRateTooltip,
      load,
      openBuyModal,
      openDealCompleteModal,
      openDealIncompleteModal,
      openDealPutDownModal,
      openDealReOpenModal,
      goEdit,
    };
  },
});
