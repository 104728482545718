


















































import type { User } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { login, logout, errorMessage, useFirebaseStore } from "@/store/firebaseStore";
import { useRouter } from "@/router/useRouter";
import { defineComponent, ref } from "@vue/composition-api";
import ResetPasswordModal from "@/components/shared/ResetPasswordModal.vue";
import { AuthErrorCodeMap } from "@/auth/error";

export default defineComponent({
  components: { LoadingAlert, ResetPasswordModal },
  setup() {
    const router = useRouter();
    const isFirstLoading = ref<boolean>(true);
    const errors = ref<string[]>([]);
    const isOpenResetPasswordModal = ref(false);
    const guideUrl = "https://uploads.strikinglycdn.com/files/d3030038-5874-4816-8ada-f8f94bd865b0/%E3%83%A1%E3%83%88%E3%82%99%E3%82%B7%E3%82%A7%E3%82%A2V2%20%E5%88%A9%E7%94%A8%E3%82%AB%E3%82%99%E3%82%A4%E3%83%88%E3%82%99.pdf";

    const user = ref<User | null>(null);
    const uid = ref<string>("");
    const email = ref("");
    const password = ref("");

    async function load() {
      try {
        const firebaseStore = useFirebaseStore();
        user.value = firebaseStore.user.value as User;
        uid.value = user.value.uid;
      } finally {
        isFirstLoading.value = false;
      }
    }
    load();

    async function handleLogin() {
      try {
        errors.value = [];
        if (!email.value) errors.value.push("メールアドレスを入力してください。");
        if (!password.value) errors.value.push("パスワードを入力してください。");
        if (errors.value.length) return;

        await login(email.value, password.value);
      } catch (e) {
        console.log("login eroor: ", e);
        if (e.response?.data?.errors) {
          errors.value = e.response.data.errors;
        } else {
          if (e instanceof FirebaseError) {
            console.log("FirebaseError", e.code);
            const code = Object.entries(AuthErrorCodeMap).find((v) => v[0] === e.code) || [
              'unknown',
              '99999',
            ];
            const message = errorMessage(e, "default") + ` (${code[1]})`
            errors.value.push(message);
          } else {
            errors.value.push(e.message);
          }
          throw e;
        }
      }
    }

    async function handleLogout() {
      await logout();
    }

    async function openResetPasswordModal() {
      isOpenResetPasswordModal.value = true;
    }

    return {
      isFirstLoading,
      guideUrl,
      user,
      uid,
      email,
      password,
      errors,
      handleLogin,
      handleLogout,
      openResetPasswordModal,
      isOpenResetPasswordModal,
    };
  },
});
