




import { provideFirebaseStore } from "@/store/firebaseStore";
import { providePharmaciesStore } from "@/store/pharmaciesStore";
import { provideSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup(props, context) {
    // Firebase
    provideFirebaseStore(context);

    // core pharmacy
    providePharmaciesStore(context);

    // Common
    provideSelectingPharmacyStore(context);
  },
});
