import { NavigationGuard } from "vue-router";
import { auth } from "@/store/firebaseStore";

export const publicGuard: NavigationGuard = async (to, from, next) => {
  auth.onAuthStateChanged(async (user) => {
    if (user) {
      next("/");
    } else {
      next();
    }
  });
};
