export const AuthErrorCodeMap = {
  'auth/admin-restricted-operation': '99001',
  'auth/argument-error': '99002',
  'auth/app-not-authorized': '99003',
  'auth/app-not-installed': '99004',
  'auth/captcha-check-failed': '99005',
  'auth/code-expired': '99006',
  'auth/cordova-not-ready': '99007',
  'auth/cors-unsupported': '99008',
  'auth/credential-already-in-use': '99009',
  'auth/custom-token-mismatch': '99010',
  'auth/requires-recent-login': '99011',
  'auth/dependent-sdk-initialized-before-auth': '99012',
  'auth/dynamic-link-not-activated': '99013',
  'auth/email-change-needs-verification': '99014',
  'auth/email-already-in-use': '99015',
  'auth/emulator-config-failed': '99016',
  'auth/expired-action-code': '99017',
  'auth/cancelled-popup-request': '99018',
  'auth/internal-error': '99019',
  'auth/invalid-api-key': '99020',
  'auth/invalid-app-credential': '99021',
  'auth/invalid-app-id': '99022',
  'auth/invalid-user-token': '99023',
  'auth/invalid-auth-event': '99024',
  'auth/invalid-cert-hash': '99025',
  'auth/invalid-verification-code': '99026',
  'auth/invalid-continue-uri': '99027',
  'auth/invalid-cordova-configuration': '99028',
  'auth/invalid-custom-token': '99029',
  'auth/invalid-dynamic-link-domain': '99030',
  'auth/invalid-email': '99031',
  'auth/invalid-emulator-scheme': '99032',
  'auth/invalid-credential': '99033',
  'auth/invalid-message-payload': '99034',
  'auth/invalid-multi-factor-session': '99035',
  'auth/invalid-oauth-client-id': '99036',
  'auth/invalid-oauth-provider': '99037',
  'auth/invalid-action-code': '99038',
  'auth/unauthorized-domain': '99039',
  'auth/wrong-password': '99040',
  'auth/invalid-persistence-type': '99041',
  'auth/invalid-phone-number': '99042',
  'auth/invalid-provider-id': '99043',
  'auth/invalid-recipient-email': '99044',
  'auth/invalid-sender': '99045',
  'auth/invalid-verification-id': '99046',
  'auth/invalid-tenant-id': '99047',
  'auth/multi-factor-info-not-found': '99048',
  'auth/multi-factor-auth-required': '99049',
  'auth/missing-android-pkg-name': '99050',
  'auth/missing-app-credential': '99051',
  'auth/auth-domain-config-required': '99052',
  'auth/missing-verification-code': '99053',
  'auth/missing-continue-uri': '99054',
  'auth/missing-iframe-start': '99055',
  'auth/missing-ios-bundle-id': '99056',
  'auth/missing-or-invalid-nonce': '99057',
  'auth/missing-multi-factor-info': '99058',
  'auth/missing-multi-factor-session': '99059',
  'auth/missing-phone-number': '99060',
  'auth/missing-verification-id': '99061',
  'auth/app-deleted': '99062',
  'auth/account-exists-with-different-credential': '99063',
  'auth/network-request-failed': '99064',
  'auth/null-user': '99065',
  'auth/no-auth-event': '99066',
  'auth/no-such-provider': '99067',
  'auth/operation-not-allowed': '99068',
  'auth/operation-not-supported-in-this-environment': '99069',
  'auth/popup-blocked': '99070',
  'auth/popup-closed-by-user': '99071',
  'auth/provider-already-linked': '99072',
  'auth/quota-exceeded': '99073',
  'auth/redirect-cancelled-by-user': '99074',
  'auth/redirect-operation-pending': '99075',
  'auth/rejected-credential': '99076',
  'auth/second-factor-already-in-use': '99077',
  'auth/maximum-second-factor-count-exceeded': '99078',
  'auth/tenant-id-mismatch': '99079',
  'auth/timeout': '99080',
  'auth/user-token-expired': '99081',
  'auth/too-many-requests': '99082',
  'auth/unauthorized-continue-uri': '99083',
  'auth/unsupported-first-factor': '99084',
  'auth/unsupported-persistence-type': '99085',
  'auth/unsupported-tenant-operation': '99086',
  'auth/unverified-email': '99087',
  'auth/user-cancelled': '99088',
  'auth/user-not-found': '99089',
  'auth/user-disabled': '99090',
  'auth/user-mismatch': '99091',
  'auth/user-signed-out': '99092',
  'auth/weak-password': '99093',
  'auth/web-storage-unsupported': '99094',
  'auth/already-initialized': '99095',
  'auth/recaptcha-not-enabled': '99096',
  'auth/missing-recaptcha-token': '99097',
  'auth/invalid-recaptcha-token': '99098',
  'auth/invalid-recaptcha-action': '99099',
  'auth/missing-client-type': '99100',
  'auth/missing-recaptcha-version': '99101',
  'auth/invalid-recaptcha-version': '99102',
  'auth/invalid-req-type': '99103',
};
