import { SetupContext, ref } from "@vue/composition-api";
import { createStoreProvider } from "@/store/utils";
import type { User } from "firebase/auth";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth, signOut, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Pharmacy } from "@/models/Pharmacy";
import Vue from "vue";

const FIREBASE_API = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
};

const firebaseApp = (() => {
  if (!getApps().length) {
    return initializeApp(FIREBASE_API);
  }
  return getApp();
})();

const auth = getAuth(firebaseApp);

export { auth };

export const isAdmin = (u: User | null) => {
  if (!u) return false;
  const attributes = (u as any)?.reloadUserInfo?.customAttributes;
  let claims;
  try {
    claims = JSON.parse(attributes);
  } catch (e) {
    console.log(e);
  }
  return !!claims?.admin;
}

// LOGIN
export const login = async (email: string, password: string) => {
  await signInWithEmailAndPassword(auth, email, password);
};

export const userSendPasswordResetEmail = (email: string) =>
  sendPasswordResetEmail(getAuth(firebaseApp), email);

// LOGOUT
export const logout = async () => signOut(auth);

export const errorMessage = (e: any, method: string) => {
  switch (e.code) {
    case "auth/cancelled-popup-request":
    case "auth/popup-closed-by-user":
      return null;
    case "auth/email-already-in-use":
      if (method.indexOf("signup") !== -1) {
        return "このメールアドレスは使用されています";
      }
      return "メールアドレスまたはパスワードが違います";
    case "auth/invalid-email":
      return "登録されているメールアドレスを入力してください。（医療機関コードではありません）";
    case "auth/user-disabled":
      return "サービスの利用が停止されています";
    case "auth/user-not-found":
      return "メールアドレスまたはパスワードが違います";
    case "auth/user-mismatch":
      if (method === "signin/popup") {
        return "認証されているユーザーと異なるアカウントが選択されました";
      }
      return "メールアドレスまたはパスワードが違います";

    case "auth/weak-password":
      return "パスワードは6文字以上にしてください";
    case "auth/wrong-password":
      return "メールアドレスまたはパスワードが違います";
    case "auth/popup-blocked":
      return "認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください";
    case "auth/operation-not-supported-in-this-environment":
    case "auth/auth-domain-config-required":
    case "auth/operation-not-allowed":
    case "auth/unauthorized-domain":
      return "現在この認証方法はご利用頂けません";
    case "auth/requires-recent-login":
      return "認証の有効期限が切れています";
    default:
      if (method.indexOf("signin") !== -1) {
        return "認証に失敗しました。しばらく時間をおいて再度お試しください";
      }
      return "エラーが発生しました。しばらく時間をおいてお試しください";
  }
};

export interface firebaseUser extends User {
  isInitialized: boolean;
  isAuthenticated: boolean;
  admin: boolean;
  pharmacyIds?: number[];
  method: string;
}
export function createFirebaseStore(context?: SetupContext) {
  const user = ref<firebaseUser | null | undefined>(null);

  (async () => {
    auth.onAuthStateChanged(async (u) => {
      if (u) {
        const httpCore = Vue.prototype.$httpCore;
        const response = await httpCore.get(`/api/v2/pharmacy/pharmacies/me`);
        const pharmacy: Pharmacy[] = response.data.map((d: Record<string, unknown>) => new Pharmacy(d));
        const pharmacyIds = pharmacy?.map((p) => p.id);
        const admin = isAdmin(u);
        console.debug("onAuthStateChanged: LOGIN", {
          uid: u.uid,
          email: u.email,
          displayName: u.displayName,
          tenantId: u.tenantId,
          admin: admin,
          pharmacyIds: pharmacyIds,
        });
        user.value = {
          isInitialized: true,
          isAuthenticated: true,
          uid: u.uid,
          email: u.email || "",
          displayName: u.displayName || "",
          photoURL: u?.photoURL || null,
          tenantId: u?.tenantId || null,
          admin: admin,
          pharmacyIds: pharmacyIds,
          method: "firebase",
        } as firebaseUser;
      } else {
        console.debug("onAuthStateChanged: LOGOUT");
        user.value = {
          isInitialized: true,
          isAuthenticated: false,
          uid: "",
          email: null,
          displayName: null,
          photoURL: null,
          tenantId: null,
          admin: false,
          pharmacyIds: undefined,
          method: "firebase",
        } as firebaseUser;
      }
    });
  })();

  return {
    user,
  };
}

const provider = createStoreProvider(createFirebaseStore, "firebaseStore");

export const provideFirebaseStore = provider.provideStore;
export const useFirebaseStore = provider.useStore;
