import { authGuard } from "@/auth/authGuard";
import { publicGuard } from "@/auth/publicGuard";
import EmptyContainer from "@/containers/EmptyContainer.vue";
import TheContainer from "@/containers/TheContainer.vue";
import ThePublicContainer from "@/containers/ThePublicContainer.vue";
import DealImageEdit from "@/views/dealImages/DealImageEdit.vue";
import DealDetail from "@/views/deals/DealDetail.vue";
import DealEdit from "@/views/deals/DealEdit.vue";
import DealPutUp from "@/views/deals/DealPutUp.vue";
import Deals from "@/views/deals/Deals.vue";
import PutUpRecommendations from "@/views/deals/PutUpRecommendations.vue";
import DispensedItems from "@/views/dispensedItems/DispensedItems.vue";
import GroupSubdivision from "@/views/groups/GroupSubdivision.vue";
import Introduction from "@/views/Introduction.vue";
import Login from "@/views/login/Login.vue";
import Page403 from "@/views/Page403.vue";
import Page404 from "@/views/Page404.vue";
import DealImageUpload from "@/views/public/DealImageUpload.vue";
import ReceiptImportFiles from "@/views/receipts/ReceiptImportFiles.vue";
import ReceiptPortal from "@/views/receipts/ReceiptPortal.vue";
import ResetPharmacy from "@/views/ResetPharmacy.vue";
import SelectPharmacy from "@/views/SelectPharmacy.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/forbidden", component: Page403 },
  {
    path: "/login",
    name: "ログイン",
    component: Login,
    beforeEnter: publicGuard,
  },
  {
    path: "/public",
    component: ThePublicContainer,
    children: [{ path: "deal-images", name: "出品画像の登録", component: DealImageUpload }],
  },
  {
    path: "/introduction",
    component: TheContainer,
    beforeEnter: authGuard,
    children: [{ path: "", name: "医薬品在庫共有サービス「メドシェア」", component: Introduction }],
  },
  {
    path: "/",
    component: TheContainer,
    beforeEnter: authGuard,
    children: [
      { path: "", redirect: "deals/put-up-recommendations" },
      { path: "deals/market", name: "購入", meta: { label: "買う / 購入" }, component: Deals, props: { type: "market" } },
      { path: "deals/buying", name: "購入取引中", meta: { label: "買う / 購入取引中" }, component: Deals, props: { type: "buying" } },
      {
        path: "deals/put-up-recommendations",
        name: "不動在庫を出品",
        meta: { label: "売る / 不動在庫を出品" },
        component: PutUpRecommendations,
      },
      { path: "deals/put-up", name: "品目指定で出品", meta: { label: "売る / 品目指定で出品" }, component: DealPutUp, props: true },
      {
        path: "deals/selling",
        name: "出品中の医薬品",
        meta: { label: "売る / 出品中の医薬品" },
        component: Deals,
        props: { type: "selling" },
      },
      {
        path: "deals/sell_dealing",
        name: "販売取引中",
        meta: { label: "売る / 販売取引中" },
        component: Deals,
        props: { type: "sell_dealing" },
      },
      { path: "deals/done", name: "過去の取引", meta: { label: "管理 / 過去の取引" }, component: Deals, props: { type: "done" } },
      {
        path: "deals/:id",
        redirect: "deals/:id",
        name: "取引詳細",
        meta: { label: "詳細" },
        component: EmptyContainer,
        children: [
          { path: "", component: DealDetail },
          { path: "edit", name: "取引編集", meta: { label: "編集" }, component: DealEdit },
          { path: "edit-images", name: "取引画像編集", meta: { label: "画像編集" }, component: DealImageEdit },
        ],
      },
      {
        path: "dispensed_items/config",
        redirect: "dispensed_items/config",
        name: "在庫表示を整理",
        meta: { label: "管理 / 在庫表示を整理" },
        component: EmptyContainer,
        children: [{ path: "/", component: DispensedItems }],
      },
      {
        path: "receipts",
        redirect: "receipts",
        name: "調剤データ登録",
        meta: { label: "管理 / 調剤データ登録" },
        component: EmptyContainer,
        children: [
          { path: "", component: ReceiptPortal },
          { path: "files", name: "レセプト登録状況", meta: { label: "登録状況" }, component: ReceiptImportFiles },
        ],
      },
      {
        path: "groups",
        redirect: "groups",
        name: "小分けサポート",
        meta: { label: "グループ / 小分けサポート" },
        component: EmptyContainer,
        children: [{ path: "", component: GroupSubdivision }],
      },
    ],
  },
  { path: "/select-pharmacy", component: SelectPharmacy, beforeEnter: authGuard },
  { path: "/reset-pharmacy", component: ResetPharmacy },
  { path: "*", component: Page404 },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
