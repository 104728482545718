




































import Vue from "vue";
import { defineComponent, ref, computed, watch } from "@vue/composition-api";
import { useHttpMedshare } from "@/plugins/axios";
import { DealableItem } from "@/models/DealableItem";

interface Props {
  selected: DealableItem | null;
}

export default defineComponent<Props>({
  name: "SelectDealableItem",
  props: {
    selected: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const httpMedshare = useHttpMedshare();
    const inputQuery = ref("");
    const selectingDealableItem = ref<DealableItem | null>(null);
    const isShow = ref(false);
    const searching = ref(false);
    const searchedDealableItems = ref<DealableItem[]>([]);

    let searchQuery = "";

    let timeoutId: number | null = null;

    async function search() {
      if (!inputQuery.value) {
        return;
      }
      const query = inputQuery.value;

      isShow.value = true;
      searching.value = true;
      searchedDealableItems.value = [];
      const req_params = { 
        query: query,
        skip_update_medicine: true,
      };
      const response = await httpMedshare.get(`/api/v2/master/dealable_items`, { params: req_params });
      searchedDealableItems.value = response.data.map((d: Record<string, unknown>) => new DealableItem(d));
      searching.value = false;

      // ドロップアイテムの右側見切れないようにするために、isShow を切り替えて再描画させる
      isShow.value = false;
      await Vue.nextTick();
      isShow.value = true;
    }


    watch(inputQuery, (newInputQuery) => {
      if (!newInputQuery) {
        isShow.value = false;
        searchedDealableItems.value = [];
        return;
      }
      if (timeoutId || newInputQuery === selectingDealableItem.value?.name) {
        return;
      }
      timeoutId = window.setTimeout(() => {
        search();
        timeoutId = null;
      }, 500);
    });

    async function selectDealableItem(dealableItem: DealableItem) {
      inputQuery.value = dealableItem.name;
      selectingDealableItem.value = dealableItem;

      const response = await httpMedshare.get(`/api/v2/master/dealable_items/${dealableItem.id}`);
      const newDealableItem = new DealableItem(response.data);
      
      context.emit("update:selected", newDealableItem);
    }

    const propsDealableItem = computed(() => {
      return props.selected;
    });
    watch(propsDealableItem, async (newDealableItem) => {
      if (!newDealableItem) {
        if (selectingDealableItem.value) {
          selectingDealableItem.value = null;
        }
        if (inputQuery.value) {
          searchQuery = "";
          inputQuery.value = "";
        }
        return;
      }
      if (selectingDealableItem.value && selectingDealableItem.value.id === newDealableItem.id) {
        return;
      }

      const response = await httpMedshare.get(`/api/v2/master/dealable_items/${newDealableItem.id}`);
      const dealableItem = new DealableItem(response.data);

      if (dealableItem) {
        searchQuery = dealableItem.name;
        inputQuery.value = dealableItem.name;
        selectingDealableItem.value = dealableItem;
        selectDealableItem(dealableItem);
      } else {
        searchQuery = "";
        inputQuery.value = "";
        selectingDealableItem.value = null;
      }
    });

    function clearInputQuery() {
      inputQuery.value = "";
      context.emit("update:selected", null);
    }

    const inputQueryClass = computed(() => {
      const classes = ["form-control", "not-last-child-border-right-none"];
      if (selectingDealableItem.value) {
        classes.push("border-right-none");
      }
      return classes;
    });

    return {
      inputQuery,
      isShow,
      search,
      selectDealableItem,
      searching,
      searchedDealableItems,
      clearInputQuery,
      inputQueryClass,
    };
  },
});
