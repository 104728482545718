








import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    summary: {
      type: Object,
      required: true,
    },
    yearInsurance: {
      type: Object,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const summary = props.summary;
    const yearInsurance = props.yearInsurance;
    const month = props.month;

    const fileCount = ref<number>(summary.hash[yearInsurance.year][yearInsurance.insurance][month].length);
    const isUploaded = ref<boolean>(fileCount.value > 0);

    function thisMonth() {
      return `${yearInsurance.year}年${month}月`;
    }

    const tooltip = ref<Record<string, string | boolean>>({
      content: `${thisMonth()}調剤分`,
      html: true,
      appendToBody: true,
    });

    return {
      isUploaded,
      tooltip,
    };
  },
});
