import { ActionFetchItemBuilder, initStoreActionFetchItemState } from "@/store/actions/createActionFetchItem";
import { createActionFindList, initStoreActionFindListState } from "@/store/actions/createActionFindList";
import { createStoreProvider } from "@/store/utils";
import { computed, ComputedRef, getCurrentInstance } from "@vue/composition-api";
import Vue from "vue";

export interface Pharmacy {
  id: number;
  companyId: number | null;
  name: string;
  postalCode: string; // 176-0012
  address1: string; // 東京都
  address2: string; // 練馬区
  address3: string; // 豊玉北4-5-11-101
  address4: string;
  phone: string;
  licenseNumber: string;
  groupsCount: number;
}

function createPharmaciesStore() {
  /* eslint-disable */
  // @ts-ignore
  const httpCore = getCurrentInstance()!.proxy.$httpCore;
  /* eslint-enable */

  const state = Vue.observable({
    ...initStoreActionFetchItemState<Pharmacy>(),
    ...initStoreActionFindListState<Pharmacy>(),
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const actionFetchBuilder = new ActionFetchItemBuilder(state, httpCore, () => {
    return "/api/v2/pharmacy/pharmacies";
  });
  const actionFetch = actionFetchBuilder.build();

  const actionFindList = createActionFindList<Pharmacy, void>(state, httpCore, () => {
    return `/api/v2/pharmacy/pharmacies/me`;
  });

  return {
    ...actionFetch,
    ...actionFindList,
  };
}

const provider = createStoreProvider(createPharmaciesStore, "pharmaciesStore");

export const providePharmaciesStore = provider.provideStore;
export const usePharmaciesStore = provider.useStore;

export function usePharmacy(pharmacyId: number): ComputedRef<Pharmacy | undefined> {
  const pharmaciesStore = usePharmaciesStore();

  return computed(() => {
    return pharmaciesStore.list.value.find((p) => {
      return p.id === pharmacyId;
    });
  });
}

export function createPharmacyAddress(pharmacy: Pharmacy): string {
  return `${pharmacy.address1 || ""}${pharmacy.address2 || ""}${pharmacy.address3 || ""}${pharmacy.address4 || ""}`;
}
