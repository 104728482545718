import { readFile } from "@/utils/file";
import { ReLineAnonymizer } from "@/utils/receipt/ReLineAnonymizer";

export class AnonymizedReceipt {
  originalText!: string;

  static async build(file: File): Promise<AnonymizedReceipt> {
    const receipt = new AnonymizedReceipt();
    receipt.originalText = await readFile(file);
    return receipt;
  }

  get valid(): string {
    // （限られた情報からではあるが）有効なレセプトファイルかどうかチェックする。
    if (!this.originalText) throw Error("ファイル内にテキストがありません。");
    else if (!(this.yk.startsWith("YK,1") || this.yk.startsWith("YK,2"))) throw Error("1行目が'YK,1'もしくは'YK,2'からはじまっていません。");
    return "";
  }

  get yk(): string {
    return this.originalText.split("\n")[0];
  }

  get anonymizedText(): string {
    function notUndefined<T>(item: T | undefined): item is T {
      return item !== undefined;
    }

    return this.originalText
      .split("\n")
      .map((line: string) => {
        const recordType = line.slice(0, 2);
        if (recordType === "RE") {
          return ReLineAnonymizer.anonymize(line.split(","));
        } else if (["HO", "KO"].includes(recordType)) {
          return;
        } else {
          return line;
        }
      })
      .filter(notUndefined)
      .join("\n");
  }
}
