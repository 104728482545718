




import DatetimeSpan from "@/components/shared/datetime/DatetimeSpan.vue";
import { Deal } from "@/models/Deal";
import { DealHistory } from "@/models/DealHistory";
import { useHttpMedshare } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: { DatetimeSpan },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const deal = props.deal as Deal;
    const httpMedshare = useHttpMedshare();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;
    const url = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/deals/${deal.id}/histories`);

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const histories = ref<DealHistory[]>([]);
    const fields = [
      { key: "display_created_at", label: "日時" },
      { key: "message", label: "取引内容" },
      { key: "display_reason", label: "理由" },
    ];
    const currentPage = ref<number>(1);
    const totalPages = ref<number>(1);
    const totalCount = ref<number>(0);

    async function fetchData() {
      if (isLoading.value) return;
      if (!myPharmacyId.value) return;

      try {
        isLoading.value = true;
        const response = await httpMedshare.get(url.value);
        histories.value = response.data.map((d: Record<string, unknown>) => new DealHistory(d));

        currentPage.value = Number(response.headers["x-current-page"]);
        totalPages.value = Number(response.headers["x-total-pages"]);
        totalCount.value = Number(response.headers["x-total-count"]);

        isFirstLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    }

    watch(myPharmacyId, () => fetchData());
    fetchData();

    return {
      isFirstLoading,
      isLoading,
      histories,
      fields,
      currentPage,
      totalPages,
      totalCount,
    };
  },
});
