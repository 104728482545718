










































import { showSelectPharmacyModal } from "@/components/shared/SelectPharmacyModal.vue";
import TheHeaderDropdownAccount from "@/containers/TheHeaderDropdownAccount.vue";
import { useFirebaseStore } from "@/store/firebaseStore";
import { Pharmacy, usePharmaciesStore } from "@/store/pharmaciesStore";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: { TheHeaderDropdownAccount },
  setup() {
    const pharmaciesStore = usePharmaciesStore();
    const selectingPharmacyStore = useSelectingPharmacyStore();
    const firebaseStore = useFirebaseStore();

    const selectPharmacyButton = computed(() => {
      if (selectingPharmacyStore.selectingPharmacyId.value) {
        pharmaciesStore.isFetching.value;
        const pharmacy = pharmaciesStore.get(selectingPharmacyStore.selectingPharmacyId.value) as Pharmacy;
        if (pharmacy) {
          return pharmacy.name;
        }
      }

      return "薬局を選択してください";
    });

    const selectPharmacyButtonColor = computed(() => {
      const user = firebaseStore.user.value;
      if (user?.admin) {
        if (user?.pharmacyIds?.indexOf(selectingPharmacyStore.selectingPharmacyId.value) === -1) {
          return "info";
        } else {
          return "danger";
        }
      }
      return "primary";
    });

    const guideUrl = "https://uploads.strikinglycdn.com/files/d3030038-5874-4816-8ada-f8f94bd865b0/%E3%83%A1%E3%83%88%E3%82%99%E3%82%B7%E3%82%A7%E3%82%A2V2%20%E5%88%A9%E7%94%A8%E3%82%AB%E3%82%99%E3%82%A4%E3%83%88%E3%82%99.pdf";

    return {
      core_url: process.env.VUE_APP_V2_APP_CORE_HOST,
      medorder_url: process.env.VUE_APP_V2_APP_MEDORDER_HOST,
      selectPharmacyButton,
      selectPharmacyButtonColor,
      showSelectPharmacyModal,
      guideUrl,
    };
  },
});
