






























































import DatetimeSpan from "@/components/shared/datetime/DatetimeSpan.vue";
import ImageWithModal from "@/components/shared/ImageWithModal.vue";
import { Deal } from "@/models/Deal";
import { DealImage } from "@/models/DealImage";
import { DealImagePresignedUrl, DealImageToken } from "@/models/DealImageToken";
import { useHttpExternal, useHttpMedshare } from "@/plugins/axios";
import { useRouter } from "@/router/useRouter";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import QRCode from "qrcode";

export default defineComponent({
  components: { DatetimeSpan, ImageWithModal },
  setup() {
    const router = useRouter();
    const httpMedshare = useHttpMedshare();
    const httpExternal = useHttpExternal();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;
    const imageApiUrl = computed(() => `${dealUrl.value}/images`);
    const tokenApiUrl = computed(() => `${imageApiUrl.value}/token`);

    const dealId = router.currentRoute.params.id;
    const dealUrl = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/deals/${dealId}`);
    const deal = ref<Deal>();

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const isLoadingQRCode = ref<boolean>(false);
    const errors = ref<string[]>([]);

    const images = ref<DealImage[]>([]);
    const uploadedImages = ref<Record<string, DealImagePresignedUrl>>({});
    const token = ref<DealImageToken>();
    const qrTargetUrl = ref<string>("https://google.com");
    const qrImageUrl = ref<string>();

    const shouldShowQR = computed(() => {
      return ImageTypeCheck() && Object.keys(uploadedImages.value).length === 0;
    });

    async function loadTokenAndMakeQr() {
      if (isLoadingQRCode.value) return;
      if (!deal.value) return;

      try {
        isLoadingQRCode.value = true;
        uploadedImages.value = {};

        const response = await httpMedshare.get(tokenApiUrl.value);
        token.value = new DealImageToken(response.data);

        const dealableItemName = encodeURI(deal.value.dealable_item.name);
        qrTargetUrl.value = `${process.env.VUE_APP_V2_APP_MEDSHARE_HOST}/public/deal-images?token=${token.value?.token}&dealable_item_name=${dealableItemName}&need_photo=${NeedPhoto()}`;
        qrImageUrl.value = await QRCode.toDataURL(qrTargetUrl.value, { width: 200 });
      } finally {
        isLoadingQRCode.value = false;
      }
    }

    async function fetchImages() {
      if (isLoading.value) return;

      try {
        isLoading.value = true;
        const response1 = await httpMedshare.get(dealUrl.value);
        deal.value = new Deal(response1.data);

        const response2 = await httpMedshare.get(imageApiUrl.value);
        images.value = response2.data.map((d: Record<string, unknown>) => new DealImage(d));
      } finally {
        isLoading.value = false;
      }
    }

    async function initialize() {
      if (!myPharmacyId.value) return;
      await fetchImages();
      await loadTokenAndMakeQr();
      isFirstLoading.value = false;
    }

    async function reloadUploadedImages() {
      clearUploadedImages();

      if (!token.value?.presigned_urls) return;

      await Promise.all(
        token.value?.presigned_urls.map(async (presigned_url: DealImagePresignedUrl, index: number) => {
          try {
            await httpExternal.get(presigned_url.presigned_url_get);
            uploadedImages.value[index.toString()] = presigned_url;
          } catch (e) {
            if (e.response.status === 403) {
              errors.value = ["QRコードの有効期限が切れています。"];
            } else if (e.response.status === 404) {
              delete uploadedImages.value[index.toString()];
            } else {
              throw e;
            }
          }
        })
      );
      uploadedImages.value = Object.assign({}, uploadedImages.value);
    }

    function clearUploadedImages() {
      uploadedImages.value = {};
    }

    async function saveImages() {
      if (isLoading.value) return;

      try {
        isLoading.value = true;

        for (const key of Object.keys(uploadedImages.value)) {
          const uploadedImage = uploadedImages.value[key];
          const reqParams = {
            deal_id: dealId,
            file_name_s3: uploadedImage.object_name,
            image_type: DealImage.imageTypeFromKey(key),
          };
          await httpMedshare.post(imageApiUrl.value, reqParams);
        }

        await router.push({ name: "取引詳細", params: { id: dealId.toString() } });
      } finally {
        isLoading.value = false;
      }
    }

    async function deleteImage(id: number) {
      if (isLoading.value) return;

      try {
        isLoading.value = true;
        await httpMedshare.delete(`${imageApiUrl.value}/${id}`);
      } finally {
        isLoading.value = false;
      }
      await fetchImages();
      await loadTokenAndMakeQr();
    }

    function displayImageType(key: string): string {
      switch (key) {
        case "0":
          return "包装";
        case "1":
          return "内容物";
        case "2":
          return "その他";
        default:
          return "不明";
      }
    }

    function ImageTypeCheck() {
      let packaging = 0;
      let contents = 0;
      let others = 0;
      for (const image of images.value) {
        if (image.image_type === "包装") {
          packaging ++;
        }else if (image.image_type === "内容物") {
          contents ++;
        }else if (image.image_type === "その他") {
          others ++;
        }
      }
      return packaging === 0 || contents === 0 || others === 0;
    }

    function NeedPhoto() {
      let packaging = 0;
      let contents = 0;
      let others = 0;
      let needPhoto = [];
      for (const image of images.value) {
        if (image.image_type === "包装") {
          packaging ++;
        }else if (image.image_type === "内容物") {
          contents ++;
        }else if (image.image_type === "その他") {
          others ++;
        }
      }
      
      if (packaging === 0) {
        needPhoto.push(0);
      }
      if (contents === 0){
        needPhoto.push(1);
      }
      if (others === 0){
        needPhoto.push(2);
      }
      return needPhoto;
    }

    watch(myPharmacyId, () => initialize());
    initialize();

    return {
      isFirstLoading,
      isLoading,
      isLoadingQRCode,
      errors,
      images,
      uploadedImages,
      qrTargetUrl,
      qrImageUrl,
      shouldShowQR,
      loadTokenAndMakeQr,
      reloadUploadedImages,
      clearUploadedImages,
      saveImages,
      deleteImage,
      displayImageType,
    };
  },
});
