






























import { Group } from "@/models/Group";
import { useHttpCore } from "@/plugins/axios";
import { Pharmacy, usePharmaciesStore } from "@/store/pharmaciesStore";
import { computed, defineComponent, ref } from "@vue/composition-api";
import Cookies from "js-cookie";

export default defineComponent({
  setup(props, context) {
    const httpCore = useHttpCore();

    const SELECTING_PHARMACY_ID_COOKIE_NAME = "selecting_pharmacy_id";

    // 表示非表示
    const minimize = ref<boolean>(false);
    const show = ref<boolean | string>("responsive");
    const pharmaciesStore = usePharmaciesStore();

    const selectingPharmacyId = computed(() => {
      // selectingPharmacyStoreからだと正しく取得できないことがあるので、cookieから直接取得する
      const pharmacyIdStr = Cookies.get(SELECTING_PHARMACY_ID_COOKIE_NAME);
      const pharmacyId = pharmacyIdStr ? parseInt(pharmacyIdStr) : 0;
      return pharmacyId;
    })
    const belongsToGroups = computed(() => {
      if (selectingPharmacyId.value) {
        pharmaciesStore.isFetching.value;
        const pharmacy = pharmaciesStore.get(selectingPharmacyId.value) as Pharmacy;
        if (pharmacy && pharmacy.groupsCount > 0) {
          return true;
        }
      }
      return false;
    });

    // 沖縄北部地区薬剤師会用スプレッドシート
    const sheetURL = "https://docs.google.com/spreadsheets/d/1c-xoKrhW55hKXLOW8ao5H4BRpe9Eud0SSX75YUKKVMw/edit?gid=0#gid=0";
    const displaySheet = ref<boolean>(false);
    async function loadGroups() {
      if (selectingPharmacyId.value) {
        const response = await httpCore.get(`/api/v2/pharmacy/pharmacies/${selectingPharmacyId.value}/groups`);
        const myGroups = response.data.map((d: Record<string, unknown>) => new Group(d));
        const groupId = 38;
        displaySheet.value = myGroups.some((g: Group) => g.id === groupId);
      }
    }
    loadGroups();

    context.root.$on("toggle-sidebar", () => {
      const sidebarOpened = show.value === true || show.value === "responsive";
      show.value = sidebarOpened ? false : "responsive";
    });
    context.root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = show.value === "responsive" || show.value === false;
      show.value = sidebarClosed ? true : "responsive";
    });

    function flipMinimize() {
      minimize.value = !minimize.value;
    }

    return {
      minimize,
      show,
      flipMinimize,
      belongsToGroups,
      displaySheet,
      sheetURL,
    };
  },
});
