export const eraStartDateList: { [key: number]: { year: number; month: number; day: number } } = {
  1: { year: 1868, month: 10, day: 23 },
  2: { year: 1912, month: 7, day: 30 },
  3: { year: 1926, month: 12, day: 25 },
  4: { year: 1989, month: 1, day: 8 },
  5: { year: 2019, month: 5, day: 1 },
};

export function eraYearToYear(era: number, eraYear: number): number {
  // 1始まり
  return eraStartDateList[era].year + eraYear - 1;
}

export function dateToEraYear(year: number, month: number, day: number): { era: number; eraYear: number } {
  if (
    year < eraStartDateList[1].year ||
    (year === eraStartDateList[1].year && month < eraStartDateList[1].month) ||
    (year === eraStartDateList[1].year && month === eraStartDateList[1].month && day < eraStartDateList[1].day)
  ) {
    throw Error(`生年月日が有効ではありません。${year}-${month}-${day}`);
  }

  // 逆順にする
  for (const era of Object.keys(eraStartDateList)
    .map(Number)
    .sort((a, b) => b - a)) {
    const eraStartYear = eraStartDateList[era].year;

    if (eraStartYear < year) {
      return { era, eraYear: year - eraStartYear + 1 };
    }

    if (eraStartYear === year) {
      const eraStartMonth = eraStartDateList[era].month;

      if (eraStartMonth < month) {
        return { era, eraYear: 1 };
      }

      if (eraStartMonth === month && eraStartDateList[era].day <= day) {
        return { era, eraYear: 1 };
      }

      const eraSignature = era - 1;
      return { era: eraSignature, eraYear: eraStartYear - eraStartDateList[eraSignature].year + 1 };
    }
  }

  throw new Error();
}

export function zeroPadding(num: number | string, length: number): string {
  return String(num).padStart(length, "0");
}
