





















import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { userSendPasswordResetEmail } from "@/store/firebaseStore";

export default defineComponent({
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
  },
  components: { LoadingAlert },
  setup(props, context) {
    const email = ref("");
    const isShowResult = ref<boolean>(false);
    const isSending = ref<boolean>(false);

    const propsOpenModal = computed({
      get: () => props.openModal,
      set: (value: boolean) => context.emit("update:openModal", value),
    });

    function closeModal() {
      propsOpenModal.value = false;
      isShowResult.value = false;
    }

    async function handleSentEmail() {
      if (!email.value) return;

      isSending.value = true;
      await userSendPasswordResetEmail(email.value);
      isSending.value = false;
      isShowResult.value = true;
    }

    return {
      email,
      propsOpenModal,
      isShowResult,
      isSending,
      handleSentEmail,
      closeModal,
    };
  },
});
