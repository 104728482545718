





























































































import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import PharmacyLink from "@/components/pharmacies/PharmacyLink.vue";
import { DispensedItem } from "@/models/DispensedItem";
import { Group } from "@/models/Group";
import { useHttpCore, useHttpMedshare } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { DateTime } from "luxon";
import Multiselect from "vue-multiselect";

export default defineComponent({
  components: { LoadingAlert, Multiselect,PharmacyLink },
  setup() {
    const httpCore = useHttpCore();
    const httpMedshare = useHttpMedshare();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;
    const url = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/dispensed_items/groups`);

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const errors = ref<string[]>([]);

    const query = ref<string>("");
    const items = ref<DispensedItem[]>([]);
    const myGroups = ref<Group[]>([]);
    const selectableGroups = ref<Array<Record<string, unknown>>>([]);

    const currentPage = ref<number>(1);
    const totalPages = ref<number>(1);
    const totalCount = ref<number>(0);

    const fields = [
      { key: "dealable_items.name", label: "医薬品名" },
      { key: "dealable_items.price", label: "薬価" },
      { key: "pharmacies.name", label: "薬局名" },
      { key: "last_dispensed_on", label: "最終調剤日" },
      { key: "dispensation_count", label: "調剤回数" },
      { key: "patient_count", label: "推定患者数" },
    ];
    const sorter = ref<Record<string, string | boolean>>({ external: true, resetable: false });
    const sorterValue = ref<Record<string, string | boolean>>({ column: "last_dispensed_on", asc: false });
    const sorterField = computed(() => (sorterValue.value.asc ? sorterValue.value.column : `-${sorterValue.value.column}`));

    async function loadGroups() {
      try {
        isLoading.value = true;

        const response = await httpCore.get(`/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/groups`);
        myGroups.value = response.data.map((d: Record<string, unknown>) => new Group(d));
        selectableGroups.value = myGroups.value.map((g) => ({ id: g.id, value: true, label: g.name }));
      } finally {
        isLoading.value = false;
      }
    }

    async function fetchItems() {
      if (isLoading.value) return;
      if (!myPharmacyId.value) return;
      errors.value = [];

      try {
        if (!selectableGroups.value.filter((g) => g.value).length && !query.value) {
          errors.value = ["グループを選択するか、医薬品名を入力してください。"];
          return;
        }
        isLoading.value = true;
        const req_params = {
          page: currentPage.value,
          query: query.value,
          sort: sorterField.value,
          group_ids: selectableGroups.value.filter((g) => g.value).map((g) => g.id),
        };

        const response = await httpMedshare.get(url.value, { params: req_params });
        items.value = response.data.map((d: Record<string, unknown>) => new DispensedItem(d));

        currentPage.value = Number(response.headers["x-current-page"]);
        totalPages.value = Number(response.headers["x-total-pages"]);
        totalCount.value = Number(response.headers["x-total-count"]);
      } catch (e) {
        if (e.response?.data?.errors) {
          errors.value = e.response.data.errors;
        } else {
          errors.value = [e.message];
          throw e;
        }
      } finally {
        isLoading.value = false;
      }
    }

    watch(currentPage, () => fetchItems());
    watch(sorterValue, () => fetchItems());

    function search(): void {
      currentPage.value = 1;
      fetchItems();
    }

    function searchNearPharmacy() {
      currentPage.value = 1;
      sorterValue.value.column = "pharmacy_distance";
    }

    function toRelativeDate(x: string): string | null {
      return DateTime.fromFormat(x, "yyyy-MM-dd").toRelative();
    }

    async function firstLoad() {
      if (!myPharmacyId.value) return;
      await loadGroups();
      if (selectableGroups.value.length == 0) {
        isFirstLoading.value = false;
        return
      };
      await fetchItems();
      isFirstLoading.value = false;
    }

    watch(myPharmacyId, () => firstLoad());
    firstLoad();

    return {
      isFirstLoading,
      isLoading,
      errors,
      query,
      items,
      fields,
      sorter,
      sorterValue,
      currentPage,
      totalPages,
      totalCount,
      search,
      toRelativeDate,
      selectableGroups,
      searchNearPharmacy,
    };
  },
});
