




























































import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { DealImagePresignedUrl } from "@/models/DealImageToken";
import { useHttpExternal } from "@/plugins/axios";
import { useRouter } from "@/router/useRouter";
import { readFiles } from "@/utils";
import { defineComponent, computed, ref } from "@vue/composition-api";

export default defineComponent({
  components: { LoadingAlert },
  setup() {
    const router = useRouter();
    const httpExternal = useHttpExternal();

    const isExpired = ref<boolean>(false);
    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const isUploaded = ref<boolean>(false);
    const files = ref<Record<number, File>>({});
    const shouldDisableUploadButton = computed<boolean>(() => Object.keys(files.value).length === 0);
    const errors = ref<string[]>([]);

    const token = router.currentRoute.query.token;
    const dealableItemName = router.currentRoute.query.dealable_item_name || "-";
    const needPhoto = router.currentRoute.query.need_photo;

    const presignedUrls = ref<DealImagePresignedUrl[]>([]);

    async function fetchPresignedUrls() {
      try {
        isLoading.value = true;

        const req_params = { token };
        const url = `${process.env.VUE_APP_V2_API_MEDSHARE_HOST}/api/v2/pharmacy/public/deal_images/presigned_urls`;
        const response = await httpExternal.get(url, { params: req_params });
        presignedUrls.value = response.data.map((d: Record<string, unknown>) => new DealImagePresignedUrl(d));
        isFirstLoading.value = false;
        isLoading.value = false;
      } catch {
        isExpired.value = true;
      }
    }

    async function uploadImages() {
      if (shouldDisableUploadButton.value) return;

      try {
        isLoading.value = true;
        errors.value = [];

        for (const key of Object.keys(files.value)) {
          const index = Number(key);
          const file = files.value[index];
          if (!file) continue;

          const presignedUrl = presignedUrls.value[index].presigned_url_put;
          await httpExternal.put(presignedUrl, file, { headers: { "content-type": file.type } });
        }

        isUploaded.value = true;
      } catch (e) {
        errors.value = ["画像アップロードでエラーが発生しました。"];
        throw e;
      } finally {
        isLoading.value = false;
      }
    }

    async function changeFile(e: Event) {
      const file = (await readFiles(e))?.item(0);
      if (!file) return;

      const fileIndex = Number((e.target as HTMLInputElement).attributes.getNamedItem("index")?.value);
      files.value[fileIndex] = file;
    }

    fetchPresignedUrls();

    return {
      isExpired,
      isFirstLoading,
      isLoading,
      isUploaded,
      errors,
      dealableItemName,
      uploadImages,
      changeFile,
      needPhoto,
    };
  },
});
