export class Receipt {
  id!: number;
  pharmacy_id!: string;
  receipt_import_file_id!: string;
  micode!: string;
  year!: string;
  month!: string;
  insurance!: string;
  file_sequence?: string;
  created_at!: string;
  updated_at!: string;
  dispensedYear!: number;
  dispensedMonth!: number;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
    this.dispensedYear = this.calculateDispensedYear();
    this.dispensedMonth = this.calculateDispensedMonth();
  }

  calculateDispensedYear(): number {
    const currentYear = parseInt(this.year);
    const currentMonth = parseInt(this.month);
    const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
    return previousYear;
  }

  calculateDispensedMonth(): number {
    const currentMonth = parseInt(this.month);
    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    return previousMonth;
  }

  get display_insurance(): string {
    switch (this.insurance) {
      case "1":
        return "社保";
      case "2":
        return "国保";
      default:
        return "不明";
    }
  }
}
