export class ReceiptImportFile {
  id!: number;
  file_name!: string;
  file_name_s3!: string;
  status!: string;
  status_message?: string;
  created_at!: string;
  updated_at!: string;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
  }

  get display_status(): string {
    switch (this.status) {
      case "unprocessed":
        return "処理中";
      case "processed":
        return "処理済み";
      case "error":
        return "エラー";
      default:
        return "不明なエラー";
    }
  }
}
