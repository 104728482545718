
















































import FileSelector from "@/components/shared/FileSelector.vue";
import { useHttpCore, useHttpExternal } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { AnonymizedReceipt } from "@/utils/receipt/AnonymizedReceipt";
import Receipts from "@/views/receipts/Receipts.vue";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: { FileSelector, Receipts },
  setup() {
    const httpCore = useHttpCore();
    const httpExternal = useHttpExternal();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;

    const isUploading = ref<boolean>(false);
    const uploadedFiles = ref<number>(0);
    const totalFiles = ref<number>(0);
    const uploadErrors = ref<Array<string>>([]);

    async function uploadFiles(files: File[]) {
      totalFiles.value = files.length;
      uploadErrors.value = [];

      try {
        isUploading.value = true;

        for (const file of files) {
          try {
            const receipt = await AnonymizedReceipt.build(file);

            // バリデーション
            receipt.valid

            // presignedUrl を取得し、S3 にアップロードする
            const response = await httpCore.get(`/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/receipt_import_files/presigned_url`);
            const presignedUrl = response.data.presigned_url;
            const object_name = response.data.object_name;
            if (file.name.split('.').pop() === "deletePatients"){
              await httpExternal.put(presignedUrl, receipt.originalText, { headers: { "content-type": "text/plain" } });
            } else {
              await httpExternal.put(presignedUrl, receipt.anonymizedText, { headers: { "content-type": "text/plain" } });
            } 

            // ファイルアップロード完了APIを叩く
            const req_params = { file_name: file.name, file_name_s3: object_name };
            await httpCore.post(`/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/receipt_import_files`, req_params);

            // アップロード済みファイル数をインクリメント
            uploadedFiles.value = uploadedFiles.value + 1;
          } catch (e) {
            console.error(e);

            const errorMessage = `${file.name}: アップロードできませんでした。[${e}]`;
            uploadErrors.value = [...uploadErrors.value, errorMessage];
          }
        }
      } finally {
        isUploading.value = false;
      }
    }

    function downloadFile() {
      const link = document.createElement('a');
      link.href = "/html/deletePatients.html";
      link.download = "deletePatients.html";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return {
      isUploading,
      uploadedFiles,
      totalFiles,
      uploadErrors,
      uploadFiles,
      downloadFile,
    };
  },
});
