


























import { logout, useFirebaseStore } from "@/store/firebaseStore";
import { defineComponent, ref } from "@vue/composition-api";
import type { User } from "firebase/auth";

export default defineComponent({
  setup() {
    const user = ref<User | null>(null);

    async function handleLogout() {
      await logout();
    }

    async function load() {
      const firebaseStore = useFirebaseStore();
      user.value = firebaseStore.user.value as User;
    }
    load();

    return {
      core_url: process.env.VUE_APP_V2_APP_CORE_HOST + "/pharmacy",
      handleLogout,
      user,
    };
  },
});
