















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const medshareUrl = "https://www.pharmacloud.co.jp/service/med-share";
    return {
      medshareUrl,
    };
  },
});
