






























import ImageWithModal from "@/components/shared/ImageWithModal.vue";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { Deal } from "@/models/Deal";
import { DealImage } from "@/models/DealImage";
import { useHttpMedshare } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: { ImageWithModal, LoadingAlert },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const deal = props.deal as Deal;

    const httpMedshare = useHttpMedshare();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;
    const url = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/deals/${deal.id}/images`);

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const images = ref<DealImage[]>([]);

    const canEdit = computed(() => deal.seller_pharmacy_id === myPharmacyId.value);

    async function fetchImages() {
      if (isLoading.value) return;
      if (!myPharmacyId.value) return;

      try {
        isLoading.value = true;

        const response = await httpMedshare.get(url.value);
        images.value = response.data.map((d: Record<string, unknown>) => new DealImage(d));
        isFirstLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    }

    watch(myPharmacyId, () => fetchImages());
    fetchImages();

    return {
      isFirstLoading,
      isLoading,
      images,
      canEdit,
    };
  },
});
