var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFirstLoading)?_c('LoadingAlert'):_c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.totalCount)+" 件のレセプトを登録済み "),_c('div',{staticClass:"card-header-actions"},[_c('CLink',{staticClass:"card-header-action btn-setting text-primary",attrs:{"to":{ name: 'レセプト登録状況' }}},[_vm._v("登録状況")])],1)]),_c('CCardBody',[_c('CAlert',{attrs:{"color":"secondary"}},[_vm._v(" ファイルの登録に際しては最終処理をシステム内部で行っており、アップロード後すぐには最後まで終了していないことがあります。"),_c('br'),_vm._v(" 登録処理の最新状況は、右上の「登録状況」リンクからご確認ください。"),_c('br'),_vm._v(" また、月初の5日から10日にかけてはレセプト登録が集中し、最終処理が終わるのにお時間がかかる場合があります。 ")]),_c('CDataTable',{attrs:{"bordered":"","fixed":"","responsive":"","loading":_vm.isLoading,"items":_vm.summary.yearInsurances,"fields":_vm.fields,"sorter":false},scopedSlots:_vm._u([{key:"year",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',{class:{ 'border-top-0': !_vm.displayYear(yearInsurance) }},[_vm._v(_vm._s(_vm.displayYear(yearInsurance)))])]}},{key:"insurance",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.displayInsurance(yearInsurance)))])]}},{key:"1",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"1"}})],1)]}},{key:"2",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"2"}})],1)]}},{key:"3",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"3"}})],1)]}},{key:"4",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"4"}})],1)]}},{key:"5",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"5"}})],1)]}},{key:"6",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"6"}})],1)]}},{key:"7",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"7"}})],1)]}},{key:"8",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"8"}})],1)]}},{key:"9",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"9"}})],1)]}},{key:"10",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"10"}})],1)]}},{key:"11",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"11"}})],1)]}},{key:"12",fn:function(ref){
var yearInsurance = ref.item;
return [_c('td',[_c('ReceiptUploadStatus',{attrs:{"summary":_vm.summary,"year-insurance":yearInsurance,"month":"12"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }