import { NavigationGuard } from "vue-router";
import Cookies from "js-cookie";
import { auth } from "@/store/firebaseStore";
import { Pharmacy } from "@/models/Pharmacy";
import Vue from "vue";

const SELECTING_PHARMACY_ID_COOKIE_NAME = "selecting_pharmacy_id";

export const authGuard: NavigationGuard = async (to, from, next) => {
  const pharmacyIdStr = Cookies.get(SELECTING_PHARMACY_ID_COOKIE_NAME);
  const pharmacyId = pharmacyIdStr ? parseInt(pharmacyIdStr) : 0;
  auth.onAuthStateChanged(async (user) => {
    if (!user) {
      // loginはpublicGuardなのでto.pathをチェックしない
      next("/login");
      return;
    }
    if (!pharmacyId) {
      // TODO: ホントは Store 経由で参照したかったけど、いったんCookieを直接参照する。
      // リダイレクトループしないように、to.pathをチェック
      if (to.path !== "/select-pharmacy") {
        return next("/select-pharmacy");
      }
    } else {
      // apiからアクセスできる薬局を取得
      const httpCore = Vue.prototype.$httpCore;
      const response = await httpCore.get(`/api/v2/pharmacy/pharmacies/me`);
      const pharmacy: Pharmacy[] = response.data.map((d: Record<string, unknown>) => new Pharmacy(d));
      const permitted = pharmacy?.map((p) => p.id)?.includes(pharmacyId);
      if (!permitted) {
        if (to.path !== "/introduction") {
          return next("/introduction");
        }
      }
    }
  });

  next();
};
