import { Receipt } from "@/models/Receipt";
import { range } from "@/utils/maths/range";
import { max } from "@popperjs/core/lib/utils/math";
import groupArray from "group-array";
import { DateTime } from "luxon";

export class ReceiptSummary {
  list!: Receipt[];
  hash!: Record<string, Record<string, Record<string, Receipt[]>>>;

  years: string[]; // ["2021", "2020", ...]
  yearInsurances: Record<string, string>[] = []; // [{year: "2021", insurance: "1"}, {year: "2021", insurance: "2"}, ...]

  public constructor(init: Receipt[]) {
    this.list = init;
    // eslint-disable-next-line
    // @ts-ignore
    this.hash = groupArray(init, "dispensedYear", "insurance", "dispensedMonth");

    this.years = Object.keys(this.hash).reverse();
    this.makeUpForLackOfLatestYears();

    this.years.forEach((year) => {
      const insurances = Array.from({ length: 2 }, (_, i) => (i + 1).toString());
      insurances.forEach((insurance) => {
        if (this.hash[year][insurance] === undefined) {
          // 該当の保険機関のレコードがなかったら、空で定義する。
          this.hash[year][insurance] = {};
        }

        this.yearInsurances.push({ year: year, insurance: insurance });

        const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
        months.forEach((month) => {
          if (!(month in this.hash[year][insurance])) {
            // 該当の月のレコードがなかったら、空で定義する。
            this.hash[year][insurance][month] = [];
          }
        });
      });
    });

    // sort desc
    this.yearInsurances = this.yearInsurances.sort(ReceiptSummary.compareYearInsurance);
  }

  /*
   * レセプトが0件だったり、最新年まで登録されていなかったら、最新年まで空で補完する。
   */
  private makeUpForLackOfLatestYears(): void {
    const thisYearNumber = DateTime.now().year;
    if (this.years.length === 0) {
      // 年の一覧が0件だったら、最新年を補完する。
      const thisYear = thisYearNumber.toString();

      this.years = [thisYear];
      this.hash[thisYear] = {};
    } else {
      const maxYearNumber = max(...this.years.map((y) => Number(y)));

      // 最新年までがなかったら。
      if (thisYearNumber > maxYearNumber) {
        const insufficientYears = range(maxYearNumber + 1, thisYearNumber, 1);
        insufficientYears.forEach((yearNumber) => {
          const year = yearNumber.toString();
          this.years = [...this.years, year].reverse();
          this.hash[year] = {};
        });
      }
    }
  }

  private static compareYearInsurance(a: Record<string, string>, b: Record<string, string>): number {
    // year の新しい順。insurance はそのままアルファベット順。
    if (a.year < b.year) return 1;
    else if (a.year > b.year) return -1;
    else {
      if (a.insurance > b.insurance) return 1;
      else if (a.insurance < b.insurance) return -1;
      else return 0;
    }
  }
}
