

































































































































































































































































































































import DealBuyModal from "@/components/deals/DealBuyModal.vue";
import DealDeliverySlip from "@/components/deals/DealDeliverySlip.vue";
import DealGroupsCell from "@/components/deals/DealGroupsCell.vue";
import DealTransferSlip from "@/components/deals/DealTransferSlip.vue";
import PharmacyLink from "@/components/pharmacies/PharmacyLink.vue";
import DatetimeSpan from "@/components/shared/datetime/DatetimeSpan.vue";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { Deal } from "@/models/Deal";
import { DispensedItem } from "@/models/DispensedItem";
import { Pharmacy } from "@/models/Pharmacy";
import { useHttpMedshare } from "@/plugins/axios";
import { useRouter } from "@/router/useRouter";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import Multiselect from "vue-multiselect";
import DealCompleteModal from "@/components/deals/DealCompleteModal.vue";
import DealReOpenModal from "@/components/deals/DealReOpenModal.vue";
import DealAllCompleteModal from "@/components/deals/DealAllCompleteModal.vue";
import DealIncompleteModal from "@/components/deals/DealIncompleteModal.vue";

export default defineComponent({
  components: {
    DatetimeSpan,
    DealBuyModal,
    DealGroupsCell,
    LoadingAlert,
    PharmacyLink,
    Multiselect,
    DealDeliverySlip,
    DealTransferSlip,
    DealCompleteModal,
    DealReOpenModal,
    DealAllCompleteModal,
    DealIncompleteModal,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const httpMedshare = useHttpMedshare();
    const router = useRouter();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;
    const urlBase = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId.value}`);
    const dealUrl = computed(() => `${urlBase.value}/deals/${props.type}`);

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const isOpenBuyModal = ref<boolean>(false);
    const isOpenDealCompleteModal = ref<boolean>(false);
    const isOpenDealReOpenModal = ref<boolean>(false);
    const isOpenDealAllCompleteModal = ref<boolean>(false);
    const isOpenDealIncompleteModal = ref<boolean>(false);

    const query = ref<string>("");
    const dispensingDays = ref<number | null>(90);
    const deals = ref<Deal[]>([]);
    const selectedDeal = ref<Deal>();
    const sellerPharmacyList = ref<Pharmacy[]>([]);
    const selectedSellerPharmacy = ref<Pharmacy>();
    const fields = computed(() => {
      switch (props.type) {
        case "market":
          return [
            { key: "groups_count", label: "出品先" },
            { key: "actions_default", label: "操作", sorter: false },
            { key: "dealable_items.name", label: "医薬品名" },
            { key: "price", label: "出品額" },
            { key: "expires_on", label: "使用期限" },
            { key: "unpacked", label: "開封状況" },
            { key: "seller_pharmacies.name", label: "出品薬局" },
            { key: "created_at", label: "出品日" },
            { key: "dispensation", label: "調剤実績" },
          ];
        case "buying":
          return [
            { key: "groups_count", label: "出品先" },
            { key: "seller_pharmacies.name", label: "出品薬局" },
            { key: "created_at", label: "出品日" },
            { key: "dealable_items.name", label: "医薬品名" },
            { key: "price", label: "出品額" },
            { key: "expires_on", label: "使用期限" },
            { key: "unpacked", label: "開封状況" },
            { key: "actions_default", label: "操作", sorter: false },
            { key: "close_action", label: "取引完了", sorter: false },
            { key: "re_open_action", label: "取引取消", sorter: false },
          ];
        case "selling":
          return [
            { key: "groups_count", label: "出品先" },
            { key: "created_at", label: "出品日" },
            { key: "dealable_items.name", label: "医薬品名" },
            { key: "price", label: "出品額" },
            { key: "expires_on", label: "使用期限" },
            { key: "unpacked", label: "開封状況" },
            { key: "actions_default", label: "取引詳細", sorter: false },
          ];
        case "sell_dealing":
          return [
            { key: "groups_count", label: "出品先" },
            { key: "buyer_pharmacies.name", label: "購入薬局" },
            { key: "created_at", label: "出品日" },
            { key: "dealable_items.name", label: "医薬品名" },
            { key: "price", label: "出品額" },
            { key: "expires_on", label: "使用期限" },
            { key: "unpacked", label: "開封状況" },
            { key: "actions_default", label: "取引詳細", sorter: false },
            { key: "close_action", label: "取引完了", sorter: false },
            { key: "re_open_action", label: "取引取消", sorter: false },
          ];
        case "done":
          return [
            { key: "groups_count", label: "出品先" },
            { key: "status", label: "取引状況" },
            { key: "seller_pharmacies.name", label: "出品薬局" },
            { key: "buyer_pharmacies.name", label: "購入薬局" },
            { key: "created_at", label: "出品日" },
            { key: "updated_at", label: "最終更新日" },
            { key: "dealable_items.name", label: "医薬品名" },
            { key: "price", label: "出品額" },
            { key: "expires_on", label: "使用期限" },
            { key: "unpacked", label: "開封状況" },
            { key: "actions_default", label: "取引詳細", sorter: false },
            { key: "incomplete_action", label: "取引中に戻す", sorter: false },
            { key: "open_print_view", label: "医薬品の譲受・譲渡の記録", sorter: false },
          ];
        default:
          return [];
      }
    });
    const sorter = { external: true, resetable: false };
    const sorterValue = ref<Record<string, string | boolean>>({ column: "created_at", asc: false });
    const sorterField = computed(() => {
      if(sorterValue.value.column === "status"){
        return (sorterValue.value.asc ? "status,seller_pharmacies.name" : "-status,-seller_pharmacies.name");
      }else{
        return (sorterValue.value.asc ? sorterValue.value.column : `-${sorterValue.value.column}`);
      } 
    });
    const currentPage = ref<number>(1);
    const totalPages = ref<number>(1);

    const printArea = ref<boolean>(false);
    const selectDeal = ref<Deal>();
    const pharmacyList = ref<Pharmacy[]>([]);
    const selectPharmacy = ref<Pharmacy>();
    const errors = ref<string[]>([]);

    const dealList = computed(() => {
      if (selectPharmacy.value) {
        return deals.value.filter((deal: Deal) => (selectPharmacy.value?.id || 0) == deal.buyer_pharmacy_id);
      } else {
        return deals.value;
      }
    });

    const totalCount = computed(() => {
      return dealList.value.length
    });

    async function fetchDeals() {
      if (isLoading.value) return;
      if (!myPharmacyId.value) return;

      errors.value = [];

      try {
        if (dispensingDays.value && dispensingDays.value < 0) {
          errors.value.push("調剤頻度は0以上の数字を入力してください。");
        }
        if (errors.value.length) return;
        isLoading.value = true;
        const req_params = {
          page: currentPage.value,
          query: query.value,
          sort: sorterField.value,
          dispensing_days: dispensingDays.value,
          seller_pharmacy_id: selectedSellerPharmacy.value?.id,
        };

        const response = await httpMedshare.get(dealUrl.value, { params: req_params });
        deals.value = response.data.map((d: Record<string, unknown>) => new Deal(d));

        currentPage.value = Number(response.headers["x-current-page"]);
        totalPages.value = Number(response.headers["x-total-pages"]);

        const dealable_item_ids = deals.value
          .map((d: Deal) => d.dealable_item_id)
          .sort((a, b) => a - b)
          .join(",");
        const response2 = await httpMedshare.get(`${urlBase.value}/dispensed_items`, { params: { dealable_item_ids } });
        const dispensedItems = response2.data.map((d: Record<string, unknown>) => new DispensedItem(d));
        deals.value = deals.value.map((deal: Deal) => {
          deal.dispensed_item = dispensedItems.find((item: DispensedItem) => item.dealable_item_id === deal.dealable_item_id);
          return deal;
        });

        if (props.type === "market") {
          const response3 = await httpMedshare.get(`${urlBase.value}/seller_pharmacies`, { params: { req_params } });
          sellerPharmacyList.value = response3.data.map((d: Record<string, unknown>) => new Pharmacy(d));
          if (
            selectedSellerPharmacy.value &&
            sellerPharmacyList.value &&
            !sellerPharmacyList.value.map((v) => v.id).includes(selectedSellerPharmacy.value.id)
          ) {
            sellerPharmacyList.value.unshift(selectedSellerPharmacy.value);
          }
        }
        const buyerPharmacyList: Array<Pharmacy> = deals.value.map((deal: Deal) => {
          return deal.buyer_pharmacy as Pharmacy;
        });
        pharmacyList.value = buyerPharmacyList.filter((element, index, self) => {
          return self.findIndex((value: Pharmacy) => value.id === element.id) === index;
        });

        isFirstLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    }

    function search(): void {
      currentPage.value = 1;
      fetchDeals();
    }

    function openBuyModal(deal: Deal): void {
      selectedDeal.value = deal;
      isOpenBuyModal.value = true;
    }

    const openDealCompleteModal = (deal: Deal) => {
      selectedDeal.value = deal;
      isOpenDealCompleteModal.value = true;
    }

    const openDealReOpenModal = (deal: Deal) => {
      selectedDeal.value = deal;
      isOpenDealReOpenModal.value = true;
    }

    const openDealAllCompleteModal = () => {
      isOpenDealAllCompleteModal.value = true;
    }

    const openDealIncompleteModal = (deal: Deal) => {
      selectedDeal.value = deal;
      isOpenDealIncompleteModal.value = true;
    }

    const actionValue = computed(() => {
      if (props.type === "sell_dealing") {
        return "販売";
      }
      if (props.type === "buying") {
        return "購入";
      }
      return "";
    });

    async function openPharmacyMarket(pharmacy: Pharmacy) {
      selectedSellerPharmacy.value = pharmacy;
      await router.push({ name: "購入" });
    }

    function sellerPharmacyLabel(pharmacy: Pharmacy) {
      return `${pharmacy.name} (${pharmacy.micode}) ${pharmacy.fullAddress}`;
    }

    function openPrintView() {
      printArea.value = true;
      const originalScrollPosition = window.scrollY;
      scrollTo(0,0);
      setTimeout(() => {
        print();
      }, 1500);
      setTimeout(() => {
        printArea.value = false;
      }, 1500);
      setTimeout(() => {
        scrollTo(0, originalScrollPosition);
      }, 1500);
    }

    function openTransferSlipPrintView(deal: Deal): void {
      selectDeal.value = deal;
      openPrintView();
    }

    function openDeliverySlipPrintView() {
      if (!selectPharmacy.value) {
        alert("薬局を選択してください。");
        return;
      }
      openPrintView();
    }

    function searchNearPharmacy() {
      currentPage.value = 1;
      sorterValue.value = {
        column: "pharmacy_distance",
        asc: true
      };
    }

    watch(
      // props.type が変更されたら（つまり画面が切り替わったら）、再読込する。
      () => props.type,
      (type: string) => {
        isFirstLoading.value = true;
        if (type) fetchDeals();
      }
    );
    watch(query, (changed: string, original: string) => {
      // 検索ワードを入力し始めたときに、dispensingDays を null にする
      if (!original) dispensingDays.value = null;
    });
    watch(currentPage, () => fetchDeals());
    watch(sorterValue, () => fetchDeals());
    watch(selectedSellerPharmacy, () => {
      currentPage.value = 1;
      fetchDeals();
    });
    watch(myPharmacyId, () => fetchDeals());

    fetchDeals();

    return {
      isFirstLoading,
      isLoading,
      isOpenBuyModal,
      query,
      dispensingDays,
      deals,
      selectedDeal,
      fields,
      sorter,
      sorterValue,
      currentPage,
      totalPages,
      totalCount,
      search,
      fetchDeals,
      openBuyModal,
      sellerPharmacyList,
      selectedSellerPharmacy,
      sellerPharmacyLabel,
      openPharmacyMarket,
      printArea,
      openTransferSlipPrintView,
      selectDeal,
      pharmacyList,
      selectPharmacy,
      dealList,
      openDeliverySlipPrintView,
      searchNearPharmacy,
      errors,
      openDealCompleteModal,
      openDealReOpenModal,
      isOpenDealCompleteModal,
      isOpenDealReOpenModal,
      openDealAllCompleteModal,
      isOpenDealAllCompleteModal,
      isOpenDealIncompleteModal,
      openDealIncompleteModal,
      actionValue,
    };
  },
});
