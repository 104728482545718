





















import DealItemView from "@/components/deals/DealItemView.vue";
import { Deal } from "@/models/Deal";
import { useHttpMedshare } from "@/plugins/axios";
import { useRouter } from "@/router/useRouter";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: { DealItemView },
  props: {
    deal: {
      type: Object,
      required: true,
    },
    openModal: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const httpMedshare = useHttpMedshare();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;

    const deal = ref<Deal>(props.deal as Deal);
    const url = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/deals/${deal.value.id}/buy`);

    const propsOpenModal = computed({
      get: () => props.openModal,
      set: (value: boolean) => context.emit("update:openModal", value),
    });
    const isShowResultModal = ref<boolean>(false);
    const isUpdating = ref<boolean>(false);

    async function submit() {
      if (isUpdating.value) return;

      try {
        isUpdating.value = true;
        await httpMedshare.post(url.value);
        isShowResultModal.value = true;
      } finally {
        isUpdating.value = false;
      }
    }

    function hideModal() {
      propsOpenModal.value = false;
    }

    async function goDetail() {
      isShowResultModal.value = false;
      if (router.currentRoute.name !== "取引詳細") {
        await router.push({ name: "取引詳細", params: { id: deal.value.id.toString() } });
      }
    }

    async function goMarket() {
      isShowResultModal.value = false;
      if (router.currentRoute.name !== "購入") {
        await router.push({ name: "購入" });
      }
    }

    watch(props, () => (deal.value = props.deal as Deal));

    watch(isShowResultModal, (newValue, oldValue) => {
      if (oldValue === true && newValue === false) {
        propsOpenModal.value = false;
        context.emit("on-submit");
      }
    });

    return {
      propsOpenModal,
      isShowResultModal,
      isUpdating,
      submit,
      goDetail,
      goMarket,
      hideModal,
    };
  },
});
